import Rails from '@rails/ujs'
import {hideFlash} from './common'
Rails.start()

const evChanged = new Event('changed')

let resizeDelayTimeout: number
let viewPortWidth: number = window.innerWidth
let viewPortHeight: number = window.innerHeight

window.addEventListener('resize', () => {
    // For Youtube oEmbed player not to reset going to full screen, need to check if document.fullscreenElement is not available
    if (document.fullscreenElement == null && (viewPortWidth != window.innerWidth || viewPortHeight != window.innerHeight) ) { // https://medium.com/@ibalajisankar/resize-event-issue-in-mobile-phones-874a8fc8c036
        viewPortWidth = window.innerWidth
        viewPortHeight = window.innerHeight
        clearTimeout(resizeDelayTimeout)
        // Event needs to be delayed for the charts
        resizeDelayTimeout = setTimeout(() => window.dispatchEvent(evChanged), 500)
    }
})

// Light / Dark mode changer ===============================================================================
let elLightDark = document.getElementById('light_dark')
elLightDark?.addEventListener('click', e => {
    let newMode = document.documentElement.classList.toggle('dark-mode')
    localStorage.setItem('dark-mode', newMode.toString())
    e.preventDefault()
    window.dispatchEvent(evChanged)
})
//================================================================================================

// Hide Flash message ====================================================================================
hideFlash()?.then(el => el.remove())
//================================================================================================

// Load Ads =================================================================================================
if (window.conf.loadDelayedAds) {
    setTimeout(() => {
        let scriptEl = document.createElement('script') as HTMLScriptElement
        scriptEl.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-${window.conf.adsensePublisherId}`
        scriptEl.async = true
        scriptEl.crossOrigin = 'anonymous'
        document.head.appendChild(scriptEl)
    }, 4000)    // Ads must be loaded with the delay min. 4s as they dramatically decrease lighthouse score If changed check: https://pagespeed.web.dev/analysis
}

// Load Analytics =================================================================================================
setTimeout(() => {
    let scriptEl = document.createElement('script') as HTMLScriptElement
    scriptEl.src = `https://www.googletagmanager.com/gtag/js?id=${window.conf.googleAnalyticsId}`
    scriptEl.async = true
    scriptEl.crossOrigin = 'anonymous'
    document.head.appendChild(scriptEl)
}, 1000)
window.dataLayer = window.dataLayer || []
function gtag(){dataLayer.push(arguments)}
gtag('js', new Date())
gtag('config', window.conf.googleAnalyticsId)

// Locale selection =================================================================================================
let langSelectEls = document.querySelectorAll('.locale-select .selector') as NodeListOf<HTMLSelectElement>
langSelectEls.forEach( (el: HTMLSelectElement) => {
    el.addEventListener('change', () => {
        window.location.href = el.value
    })
})

// Form submit auto spinner =================================================================================================
document.querySelectorAll('form').forEach(f => f.addEventListener('submit', e => {
    if (e.submitter && !e.submitter.classList.contains('no-auto-spinner')) {
        e.submitter.classList.add('btn-spinner')
    }
}))


// Full Screen Image zoom ==============================================================================================
document.querySelectorAll('img').forEach((elImg: HTMLImageElement) => {
    elImg.style.cursor = 'pointer'
    elImg.addEventListener('click', e => {
        e.preventDefault()
        e.stopPropagation()
        e.stopImmediatePropagation()

        const elFs = document.createElement('div')
        elFs.classList.add('fs-image')
        elFs.style.backgroundImage = `url('${elImg.src}')`
        document.body.appendChild(elFs)

        history.pushState(null, '')
        window.addEventListener('popstate', () => elFs.remove(), { once: true })
        elFs.addEventListener('click', () => elFs.remove())

        // Close on key press /////////////////////////////////////////
        elFs.tabIndex = -1
        elFs.focus()
        elFs.addEventListener('keydown', () => elFs.remove())
    })
})